import './App.css';

function App() {

  var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const chooseRandom = (arr, num = 1) => {
    const res = [];
    for (let i = 0; i < num;) {
      const random = Math.floor(Math.random() * arr.length);
      if (res.indexOf(arr[random]) !== -1) {
        continue;
      };
      res.push(arr[random]);
      i++;
    };
    return res;
  };

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div className="App">
      <h1>PUTTÓ SZÁM GENERÁTOR</h1>
      <button className="button" onClick={() => refreshPage()}>ÚJ SZÁMOK</button>
      <div className="number-container">
        {chooseRandom(arr, 8).sort(function (a, b) { return a - b }).map((number => {
          return <p className="number" key={number}>{number}</p>
        }))}
      </div>
    </div>
  );
}

export default App;
